import moment from "moment-timezone";

export class CommentModel {
    constructor(comment) {
        this.id = comment.id;
        this.userName = comment.user.name ?? '';
        this.ticketId = comment.ticket_classroom_arrangement_id ?? ''
        this.content = comment.content ?? '';
        this.createdAt = this.formatTime(comment.created_at);
        this.updatedAt = this.formatTime(comment.updated_at);
        this.isEdit = this.checkEdit(comment.created_at, comment.updated_at);
        this.images = comment?.mediaList ?? []
    }
    formatTime(date) {
        if (date) {
            return moment(date).format('HH:mm:ss DD-MM-YYYY');
        }
    }
    checkEdit(createdAt, updatedAt) {
        const createdAtDate = moment.tz(createdAt, "Asia/Ho_Chi_Minh");
        const updatedAtDate = moment.tz(updatedAt, "Asia/Ho_Chi_Minh");
        return createdAtDate.isBefore(updatedAtDate);
    }
}