<template>
  <div>
    <div class="form-comment">
      <div>
        <span>Người tạo: </span>
        <el-input :value="comment.userName" disabled/>
      </div>
      <div>
        <span>Thời gian tạo: </span>
        <el-input :value="comment.createdAt" disabled/>
      </div>
    </div>
    <div style="margin-top: 20px">
      <span>Nội dung</span>
      <div style="border: 1px solid lightgray; border-radius: 3px; padding: 15px">
        <ckeditor :editor="editor" v-model="comment.content"></ckeditor>
      </div>
    </div>
    <div class="action">
      <button class="btn-custom" style="background: #dddbdb; color: black" @click="closeDialog">Đóng</button>
      <button class="btn-custom" style="background: #5E00B6; color: white" @click="handleUpdateComment">Cập nhật
      </button>
    </div>
  </div>
</template>

<script>
import {UPDATE_COMMENT} from "../../../../core/services/store/task-management/task-management.module";
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";

export default {
  name: 'DialogUpdateCommentTicketDetail',
  data() {
    return {
      commentUpdate: '',
      imageURLs: [],
      newFiles: [],
      newImages: [],
      listImageDelete: [],
      editor: CustomEditor,
    }
  },
  props: {
    comment: {
      type: Object,
      default: {},
      required: true
    },
  },
  mounted() {
    this.commentUpdate = this.comment.content
    this.imageURLs = this.comment.images
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    handleDeleteImage(index) {
      if (typeof this.imageURLs[index].id === 'number') {
        this.listImageDelete.push(this.imageURLs[index].id)
      }
      this.imageURLs.splice(index, 1);
    },
    handleImageUpload(e) {
      const fileUpload = e.target.files || e.dataTransfer.files;
      if (!fileUpload || fileUpload.length === 0) return;
      const filesArray = Array.from(fileUpload);
      this.newFiles = filesArray;
      this.newImages = [...this.newImages, ...filesArray]
      this.imageURLs = [
        ...this.imageURLs,
        ...filesArray.map((file, index) => ({
          id: `ImageId-${this.imageURLs.length + index + 1}`,
          url: URL.createObjectURL(file),
        }))
      ];
    },

    async handleUpdateComment() {
      const formData = new FormData();
      formData.append("id", this.comment.id);
      formData.append("ticket_classroom_arrangement_id", this.$route.params.id);
      formData.append("content", this.comment.content);
      if (this.listImageDelete.length > 0) {
        this.listImageDelete.forEach(image => formData.append("listImageDelete[]", image));
      } else {
        formData.append("listImageDelete[]", []);
      }
      this.newImages.forEach(image => formData.append("images[]", image));
      try {
        const response = await this.$store.dispatch(UPDATE_COMMENT, formData);
        if (!response.error) {
          this.closeDialog();
          this.$message.success('Bạn đã cập nhật bình luận thành công!');
          this.$emit('getListComment');
        }
      } catch (error) {
        console.error("Lỗi cập nhật bình luận:", error);
      }
    }
  },
}
</script>

<style scoped>
.show-image {
  overflow-x: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.preview-image {
  width: auto;
  height: 50px;
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid lightgray;
}

.form-comment {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 30px;
}

.action {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.comment-image:hover i {
  display: flex;
}

.btn-custom {
  padding: 10px 15px;
  border-radius: 5px;
}

.button-delete {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

.button-delete i {
  display: none;
}

.button-delete i:hover {
  color: red;
}
</style>