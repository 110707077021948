<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="form-comment">
      <div>
        <span>Người tạo: </span>
        <el-input :value="comment.userName" disabled/>
      </div>
      <div>
        <span>Thời gian tạo: </span>
        <el-input :value="comment.createdAt" disabled/>
      </div>
    </div>
    <div style="margin-top: 20px">
      <span>Nội dung</span>
      <div class="note" style="border: 1px solid lightgray; border-radius: 3px; padding: 15px">
        <span v-html="comment.content" style="border: none; background: transparent"> </span>
<!--        <div class="show-image" v-if="comment.images.length > 0">-->
<!--          <img v-for="image in comment.images" :src="image" alt="Preview" class="preview-image"/>-->
<!--        </div>-->
      </div>
    </div>
    <div class="action">
      <button class="btn-custom" style="background: #dddbdb; color: black" @click="closeDialog">Đóng</button>
      <button class="btn-custom" style="background: #5E00B6; color: white" @click="deleteComment">Xóa</button>
    </div>
  </div>
</template>

<script>
import {DELETE_COMMENT} from "../../../../core/services/store/task-management/task-management.module";

export default {
  name: 'DialogConfirmDeleteComment',
  props: {
    comment: {
      type: Object,
      default: {},
      required: true
    },
  },
  methods: {
    deleteComment() {
      const payload = {
        id: this.comment.id
      }
      this.$store.dispatch(DELETE_COMMENT, payload).then(res => {
          if (!res.error) {
            this.$emit('getListComment')
            this.$message.success('Bạn đã xoá bình luận thành công!')
            this.closeDialog()
          }
      }).catch(e => {
        console.log(e, 'Delete comment ticket detail error!')
      })
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
  }
}
</script>

<style scoped>
  .note img{
    width: 200px;
    height: 100px;
  }
  .show-image {
    overflow-x: auto;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .preview-image {
    width: auto;
    height: 50px;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid lightgray;
  }
  .form-comment {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
  }
  .action {
    margin-top: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
  }
  .btn-custom {
    padding: 10px 15px;
    border-radius: 5px;
  }
</style>